* {
  box-sizing: border-box;
}

.font-header {
  font-family: 'Khula', sans-serif;

}

.Khula {
  font-family: 'Khula', sans-serif;
}

.color-cabiar {
  /* color: #213CC1; */
  color: #67c4ff;
}


.progress-bar-container {
  width: 100%;
  height: 15px;
  background-color: #FFFFFF29;
  border-radius: 25px;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #213CC1;
  border-radius: 25px;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
}

.inputs {
  font-family: 'Khula', Sans-serif;
  font-size: 16px;
  font-weight: 400;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ededed;
  color: #2a2a2a;
  padding: 20px 25px 20px 25px;
  border-radius: 10px;
  outline: none;
  background-color: #F8F8F8;
}

.label-inputs {
  display: block;
  color: #2a2a2a;
  font-family: "Khula", Sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 0px 5px 0px;
}

.btn-social {
  transition: 0.4s ease all;
}

.btn-social:hover {
  transform: scale(0.9);
  background-color: #2d2d2d;
}

.btn-send {
  transition: 0.4s ease all;
}

.btn-send:hover {
  background-color: #2d2d2d;
}

.btn-all {
  transition: 0.4s ease all;
  cursor: pointer;
}

.btn-all:hover {
  background-color: #2d2d2d;
}

.btn-home {
  transition: 0.4s ease all;
  cursor: pointer;
}

.btn-home:hover {
  background-color: #213CC1;
  border-color: #213CC1;
}

.line-h-title {
  line-height: 1.2
}

.line-h-1 {
  line-height: 1.1
}

.card-services {
  transition: 0.2s cubic-bezier(0.4, 0, 1, 1) all
}

.card-services:hover {
  background-color: #2a2a2a;
  color: white;
}

.card-services:hover a,
.card-services:hover p,
.card-services:hover div {
  color: white;
}

#car_move {
  box-shadow: 1px 1px 5px #9f9f9f;
  border: 2px solid #ffffff;
}

.img_user {
  width: 120px;
  height: 120px;
  border-radius: 80px;
  box-shadow: 0px 0px 5px #9f9f9f;
  border: 4px solid white;
  margin-bottom: 15px;
}

.cont-infu {
  justify-content: flex-start;
  padding: 25px;
  border-top: 7px solid #f3f3f3;
}

.bgaout {
  background-repeat: no-repeat;
  background-origin: unset;
  background-position-y: 39%;
}

.redes-equipo {
  transition: 0.4s ease all;
}

.hover-equipo:hover .redes-equipo {
  height: 100%;
  opacity: 1;
}

.menu-movil {
  transition: 0.4s ease all;
}

.open-menu {
  left: 0;
}

.icon-store {}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.399);
  z-index: 100;
  display: none;
  opacity: 0;
}

.modal-body {
 
}

.modal-title {

}
.close-modal{
  display: none;
  opacity: 0;
}
.open-modal{
  display: flex;
  opacity: 1;
}

.titleP {
 
  font-size: 22px;
  margin-top: 10px;
  font-weight: 700;
}
.desc {
  font-size: 18px;
  text-align: justify;
}
.titleD {
 
font-size: 18px;
margin-top: 10px;
font-weight: 600;
text-align: left;
}